export default function useDialogPolyfill(el: Ref<HTMLDialogElement | null>) {
    // If dialog is supported
    const isSupported = computed(() => typeof HTMLDialogElement === 'function');

    // Add class to target CSS
    const dialogClasses = computed(() => [
        isSupported.value ? '' : 'dialog-polyfill',
    ]);

    // Polyfill HTML <dialog> for Safari <15 etc.
    onMounted(async () => {
        if (!isSupported.value && el.value) {
            const dialogPolyfill = (await import('dialog-polyfill')).default;
            dialogPolyfill.registerDialog(el.value);
        }
    });

    return {
        isSupported,
        dialogClasses,
    };
}
